import BigNumber from 'bignumber.js';
import { erc20ABI } from './abi';
import { store } from '@/store';

export const MAX_SUPPLY_BST = new BigNumber('100000000000000000000000000');
export const UNLIMITED_ALLOWANCE_IN_BASE_UNITS = new BigNumber(2).pow(256).minus(1);

const getTokenAllowance = async (
  tokenAddress: string,
  spenderAddress: string
): Promise<BigNumber> => {
  const userAddress = store.getters['wallet/address'];
  const web3Instance = store.getters['wallet/web3'];

  const tokenContract = new web3Instance.eth.Contract(erc20ABI, tokenAddress);
  const tokenAllowance = await tokenContract.methods.allowance(userAddress, spenderAddress).call();
  const tokenAllowanceBN = new BigNumber(tokenAllowance);
  return tokenAllowanceBN;
};

const getTokenBalance = async (
  tokenAddress: string,
  walletAddress?: string
): Promise<BigNumber> => {
  const userAddress = walletAddress || store.getters['wallet/address'];
  const web3Instance = store.getters['wallet/web3'];

  const tokenContract = new web3Instance.eth.Contract(erc20ABI, tokenAddress);
  const tokenBalance = await tokenContract.methods.balanceOf(userAddress).call();
  const tokenBalanceBN = new BigNumber(tokenBalance);
  return tokenBalanceBN;
};

const getTotalSupply = async (tokenAddress: string): Promise<BigNumber> => {
  const web3Instance = store.getters['wallet/web3'];

  const tokenContract = new web3Instance.eth.Contract(erc20ABI, tokenAddress);
  const totalSupply = await tokenContract.methods.totalSupply().call();
  const totalSupplyBN = new BigNumber(totalSupply);
  return totalSupplyBN;
};

const setTokenAllowance = async (
  tokenAddress: string,
  spenderAddress: string,
  allowanceAmount: BigNumber
): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const userAddress = store.getters['wallet/address'];
    const web3Instance = store.getters['wallet/web3'];

    const tokenContract = new web3Instance.eth.Contract(erc20ABI, tokenAddress);
    tokenContract.methods
      .approve(spenderAddress, allowanceAmount.toFixed())
      .send({ from: userAddress })
      .once('confirmation', () => {
        console.log('confirmation');
        resolve(true);
      })
      .once('error', (error: Error) => {
        reject(error);
      });
  });

export { getTokenAllowance, getTokenBalance, getTotalSupply, setTokenAllowance };
