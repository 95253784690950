import BigNumber from 'bignumber.js';
import { oceanStakingABI, bsptPoolABI } from './abi';
import { store } from '@/store';
import { config } from '@/config';

const singleAssetPoolDeposit = async (depositAmount: BigNumber): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    const userAddress = store.getters['wallet/address'];
    const web3Instance = store.getters['wallet/web3'];

    const sBSTTokenContract = new web3Instance.eth.Contract(
      oceanStakingABI,
      config.stakingContractAddress
    );

    const BSTTokenContract = new web3Instance.eth.Contract(
      oceanStakingABI,
      config.bstTokenContractAddress
    );

    let wDepositAmount = depositAmount;
    const maxDeposit = BSTTokenContract.methods.balanceOf(userAddress).call();

    maxDeposit
      .then((maxD: string) => {
        const maxDep = new BigNumber(maxD);
        if (maxDep.isLessThan(wDepositAmount)) {
          wDepositAmount = maxDep;
        }

        sBSTTokenContract.methods
          .deposit(wDepositAmount.toFixed())
          .send({ from: userAddress })
          .once('confirmation', () => {
            // handle confirmation
            resolve(true);
          })
          .once('error', (error: Error) => {
            reject(error);
          });
      })
      .catch((error: Error) => {
        reject(error);
      });
  });

const singleAssetPoolWithdraw = async (withdrawAmount: BigNumber): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    const userAddress = store.getters['wallet/address'];
    const web3Instance = store.getters['wallet/web3'];

    const tokenContract = new web3Instance.eth.Contract(
      oceanStakingABI,
      config.stakingContractAddress
    );

    const maxBalance = tokenContract.methods.balanceOf(userAddress).call();
    let wAmount = withdrawAmount;
    maxBalance.then((maxB: string) => {
      const maxBal = new BigNumber(maxB);
      if (maxBal.isLessThan(wAmount)) {
        wAmount = maxBal;
      }

      tokenContract.methods
        .withdraw(wAmount.toFixed())
        .send({ from: userAddress })
        .once('confirmation', () => {
          // handle confirmation
          resolve(true);
        })
        .once('error', (error: Error) => {
          reject(error);
        });
    });
  });

const bsptPoolDeposit = async (depositAmount: BigNumber, tokenAddress: string): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    const userAddress = store.getters['wallet/address'];
    const web3Instance = store.getters['wallet/web3'];

    const tokenContract = new web3Instance.eth.Contract(bsptPoolABI, config.bsptPoolAddress);

    tokenContract.methods
      .deposit(tokenAddress, depositAmount.toFixed())
      .send({ from: userAddress })
      .once('confirmation', () => {
        // handle confirmation
        resolve(true);
      })
      .once('error', (error: Error) => {
        reject(error);
      });
  });

export { singleAssetPoolDeposit, singleAssetPoolWithdraw, bsptPoolDeposit };
