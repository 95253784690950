





































































































































































































































































































































































import Vue from 'vue';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ProgressOverlay from '@/components/overlay/ProgressOverlay.vue';
import { config } from '@/config';
import {
  getTokenAllowance,
  getTokenBalance,
  MAX_SUPPLY_BST,
  setTokenAllowance,
  UNLIMITED_ALLOWANCE_IN_BASE_UNITS,
} from '@/contracts/erc20';
import {
  singleAssetPoolDeposit,
  singleAssetPoolWithdraw,
  bsptPoolDeposit,
} from '@/contracts/oceanstaking';
import { fromWei, toWei } from '@/utils';

dayjs.extend(duration);
dayjs.extend(utc);

export default Vue.extend({
  name: 'AmountForm',
  components: { ProgressOverlay },
  props: {
    currency: String,
    secondaryCurrency: String,
    isDeposit: Boolean,
    lockedUntil: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
    },
    actionPendingText: {
      type: String,
      default: 'Transaction pending...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipText: String,
    bsptPool: {
      type: Boolean,
      default: false,
    },
    poolId: {
      type: String,
      required: true,
    },
    tokenAddress: {
      type: String,
      required: true,
    },
    tokenBalance: {
      type: Number,
      required: true,
    },
    sTokenAddress: {
      type: String,
      default: '',
    },
    sTokenBalance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      allowanceInputAmount: 0,
      allowanceBalanceBN: new BigNumber(0),
      adjustAllowanceMode: false,
      inputAmount: 0,
      infiniteAllowance: true,
      isDepositing: false,
      isGettingTokenWalletBalance: false,
      isGettingTokenAllowance: false,
      isSettingTokenAllowance: false,
      isWithdrawing: false,
      percentage: 0,
      allowancePercentage: 0,
      remaingLockedTime: 0,
      savedAmount: 0.0,
      timerInterval: -1,
      tokenWalletBalanceBN: new BigNumber(0),
      activeBsptWithdrawal: false,
    };
  },
  computed: {
    ...mapGetters('pool', ['pool']),
    ...mapGetters('token', ['token']),
    ...mapState('wallet', ['address']),
    allowanceBalance(): number {
      return fromWei(this.allowanceBalanceBN);
    },
    // We only keep track of staked amount if this is an asset pool for the purposes
    // of knowing if we should display certain input elements.
    bsptStakedAmount(): number {
      if (this.isDeposit && this.bsptPool) {
        // In the case of this being a bspt pool and if it's deposit, the
        // token balance prop is actually the amount of tokens the user has deposited
        return this.tokenBalance;
      }

      return 0;
    },
    balance(): number {
      if (!this.isDeposit) return this.tokenBalance;
      return fromWei(this.tokenWalletBalanceBN);
    },
    contractAddress(): string {
      return this.bsptPool ? config.bsptPoolAddress : config.stakingContractAddress;
    },
    depositAmount(): number {
      return this.inputAmount * this.percentage;
    },
    bsptDepositForbidden(): boolean {
      if (!this.bsptPool) return false;

      const userHasDeposit = this.pool(this.poolId).hasUserBSPTDeposit(this.tokenAddress);
      const tokenAllowedForDeposit = this.token(this.tokenAddress).allowedForDeposit;
      return userHasDeposit || !tokenAllowedForDeposit;
    },
    remainingTimeFormatted(): string {
      const tempDuration = dayjs.duration(this.remaingLockedTime);

      const months = tempDuration.months();
      const month = months ? `${months}m ` : '';

      const days = tempDuration.days();
      const day = days ? `${days}d ` : '';

      const hours = tempDuration.hours();
      const hour = hours ? `${hours}h ` : '';

      const minutes = tempDuration.minutes();
      const minute = minutes ? `${minutes}m ` : '';

      const seconds = tempDuration.seconds();
      const second = seconds ? `${seconds}s` : '';

      return `${month}${day}${hour}${minute}${second}`;
    },
    formDisabled(): boolean {
      return this.disabled || this.isGettingTokenAllowance || this.isGettingTokenWalletBalance;
    },
    hasUnlimitedApproval(): boolean {
      return this.allowanceBalanceBN.gte(MAX_SUPPLY_BST);
    },
  },
  mounted() {
    this.getTokenAllowance();
    this.getTokenBalance();
    this.startTimer();
    this.updateBsptPoolWithdrawalAmount();
  },
  beforeDestroy() {
    this.resetTimer();
  },
  methods: {
    ...mapActions('pool', ['updatePoolBalance']),
    ...mapMutations('pool', ['setUserPoolBalances']),

    async getTokenAllowance() {
      this.adjustAllowanceMode = false;
      this.isGettingTokenAllowance = true;

      this.allowanceBalanceBN = await getTokenAllowance(this.tokenAddress, this.contractAddress);
      this.isGettingTokenAllowance = false;
    },

    getLocaleString(number: number): string {
      return number?.toLocaleString();
    },

    // In case the this is withdrawal of bspt and the user has
    // some tokens staked which they can withdraw, update the input to 100%

    // Also, set the variable of activeBsptWithdrawal
    // to true (used for toggling amount input between disabled and enabled)
    updateBsptPoolWithdrawalAmount() {
      if (this.bsptPool && !this.isDeposit && this.tokenBalance > 0) {
        this.inputAmount = this.tokenBalance;
        this.percentage = 100;
        this.activeBsptWithdrawal = true;
      }
    },

    async getTokenBalance() {
      this.isGettingTokenWalletBalance = true;
      this.inputAmount = 0;
      this.percentage = 0;
      this.tokenWalletBalanceBN = await getTokenBalance(this.tokenAddress);
      this.isGettingTokenWalletBalance = false;
    },

    depositOceanStaking() {
      this.isDepositing = true;
      const amountBN = new BigNumber(toWei(this.inputAmount).toString());

      if (this.bsptPool) {
        bsptPoolDeposit(amountBN, this.tokenAddress)
          .then(() => {
            // Make sure that we fetch the new token balance
            this.getTokenBalance();
            this.getNewPoolBalances(true);
          })
          .finally(() => {
            this.isDepositing = false;
          });

        return;
      }

      singleAssetPoolDeposit(amountBN)
        .then(() => {
          // Make sure that we fetch the new token balance
          this.getTokenBalance();
          this.getNewPoolBalances(true);
        })
        .finally(() => {
          this.isDepositing = false;
        });
    },

    withdrawOceanStaking() {
      this.isWithdrawing = true;
      const amountBN = new BigNumber(toWei(this.inputAmount));
      const ratio = new BigNumber(toWei(this.pool(this.poolId).amounts[0].ratio));
      BigNumber.config({ ROUNDING_MODE: 3 });
      const sBSTAmount = new BigNumber(amountBN.dividedBy(ratio).toFixed(0));
      /*
        prints for future debugging

      console.log('inputAmount :', this.inputAmount);
      console.log('amountBN    :', amountBN.toString());
      console.log('ratio       :', ratio.toString());
      console.log('sBSTAmount  :', sBSTAmount.toString());

      console.log('Smart Contr.:', sBSTAmount);
      */

      singleAssetPoolWithdraw(sBSTAmount)
        .then(() => {
          // Make sure that we fetch the new token balance
          this.getTokenBalance();
          this.getNewPoolBalances();
        })
        .finally(() => {
          this.isWithdrawing = false;
        });
    },

    getNewPoolBalances(updateLockedUntil = false) {
      const tempLockedUntil = updateLockedUntil
        ? dayjs.utc().add(2, 'day').unix()
        : this.lockedUntil;

      this.updatePoolBalance({ poolId: this.poolId });
      if (this.sTokenAddress) {
        getTokenBalance(this.sTokenAddress).then((sAmountBN) => {
          const sAmount = fromWei(sAmountBN);
          this.setUserPoolBalances({
            poolId: this.poolId,
            sAmount,
            lockedUntil: tempLockedUntil,
            // TODO: make this more customizable
            tokenId: this.tokenAddress,
          });
        });
      }
    },

    setTokenAllowance() {
      this.isSettingTokenAllowance = true;
      const allowanceAmountBN = this.infiniteAllowance
        ? UNLIMITED_ALLOWANCE_IN_BASE_UNITS
        : new BigNumber(toWei(this.allowanceInputAmount));

      setTokenAllowance(this.tokenAddress, this.contractAddress, allowanceAmountBN)
        .then(() => {
          this.getTokenAllowance();
        })
        .finally(() => {
          this.isSettingTokenAllowance = false;
        });
    },

    startTimer(): void {
      // Skip if component is in deposit mode
      if (this.isDeposit) return;

      if (this.timerInterval) {
        this.resetTimer();
      }
      this.timerInterval = window.setInterval(() => {
        this.updateRemainingLockedTime();
      }, 1000);
    },
    resetTimer(): void {
      if (this.timerInterval) {
        window.clearInterval(this.timerInterval);
      }
    },

    updatePercentage(amount: number): void {
      this.percentage = this.calculatePercentage(amount, this.balance);
      this.inputAmount = amount;
    },

    updateAmount(percentage: number): void {
      // TODO create here two vars, one for display one for actual calculation
      // this.inputAmount = this.round2decimals(this.calculatePercentage(percentage, this.balance));
      this.inputAmount = this.calculatePercentage(percentage, this.balance);
      this.percentage = percentage;
    },

    updateAllowancePercentage(allowanceAmount: number): void {
      this.allowancePercentage = allowanceAmount / this.balance;
      this.allowanceInputAmount = allowanceAmount;
    },

    updateAllowanceAmount(percentage: number): void {
      this.allowanceInputAmount = this.round2decimals(
        this.calculatePercentage(percentage, this.balance)
      );
      this.allowancePercentage = percentage;
    },

    updateRemainingLockedTime(): void {
      const lockedDate = dayjs.unix(this.lockedUntil);
      const now = dayjs();
      this.remaingLockedTime = lockedDate.diff(now);
      if (this.remaingLockedTime <= 0) this.resetTimer();
    },

    calculatePercentage(percentage: number, amount: number): number {
      return amount * percentage;
    },
    round2decimals(number: number): number {
      return Math.round(Math.floor(number * 100)) / 100;
    },
  },
  watch: {
    address() {
      this.getTokenAllowance();
      this.getTokenBalance();
    },
    lockedUntil() {
      this.startTimer();
    },
    tokenAddress() {
      this.getTokenAllowance();
      this.getTokenBalance();
    },
  },
});
